import { Controller } from "stimulus";
import { debounce } from "debounce";

export default class extends Controller {
  static targets = [];

  initialize() {
  }

  before(event) {
    if (event.target.closest("[data-skip-autosave=true]")) return;
    if (event.target.dataset.submitting) return Rails.stopEverything(event);
    this.setStateLoading(event);
    this.storeFocusedInput();
  }

  beforeSend(event) {
    event.detail[0].timeout = 15000;
    return true;
  }

  complete(event) {
    if (event.target.closest("[data-skip-autosave=true]")) return;
    this.setStateDefault(event);
    if (event.detail[0].status == 200) {
      let data = JSON.parse(event.detail[0].response);
      this.processUpdates(data);
      this.restoreFocusedInput();
      this.setStateInvalid(event, data);
    } else {
      this.restoreFocusedInput();
      this.setStateError(event);
      this.reportErrorFromEvent(event);
    }

  }

  save(event) {
    if (event.target.closest("[data-skip-autosave=true]")) return;
    setTimeout(() => {
      Rails.fire(event.target.form, "submit");
    }, 15);
  }

  setStateLoading(event) {
    event.target.dataset.submitting = true;
    let button = event.target.querySelector(".btn");
    button.classList.remove("border-danger")
    button.innerHTML = '<div class="spinner-border spinner-border-sm text-primary" role="status"></div>';
    let input = event.target.querySelector("input.is-invalid");
    input && input.classList.remove("is-invalid");
  }

  setStateDefault(event) {
    delete event.target.dataset.submitting;
    let button = event.target.querySelector(".btn");
    button.innerHTML = '<em class="fas fa-check text-primary"></em>';
  }

  setStateInvalid(event, data) {
    if (data.success) return;
    data.errors.forEach(error => {
      this.showToast(error, "danger");
    });
    if (data.update_type != "expenses") {
      event.target.querySelector("input.form-control").classList.add("is-invalid");
      event.target.querySelector(".btn").classList.add("border-danger");
    }
  }

  setStateError(event) {
    this.showToast("Salvare nereușită din motive necunoscute. Reîncărcați pagina!", "danger");
    event.target.querySelector("input.form-control").classList.add("is-invalid");
    event.target.querySelector(".btn").classList.add("border-danger");
  }

  storeFocusedInput() {
    let focusedInput = document.querySelector("input:focus");
    if (focusedInput) {
      this.focusedInputId = focusedInput.getAttribute("id");
    } else {
      this.focusedInputId = undefined;
    }
  }

  restoreFocusedInput() {
    if (this.focusedInputId) {
      let focusedInput = document.querySelector("#" + this.focusedInputId);
      if (focusedInput) focusedInput.focus();
    }
  }

  processUpdates(data) {
    for (const dom_id in data.updates) {
      if (Object.hasOwnProperty.call(data.updates, dom_id)) {
        const content = data.updates[dom_id];
        let element = document.querySelector(dom_id);
        if (element) element.innerHTML = content;
      }
    }
    for (const kind in data.amounts) {
      if (Object.hasOwnProperty.call(data.amounts, kind)) {
        const content = data.amounts[kind];
        let elements = document.querySelectorAll("[data-amount=" + kind + "]");
        elements.forEach(function(element) {
          element.innerHTML = content;
        })
      }
    }
  }

  showToast(message, color) {
    let html = `
      <div class="toast align-items-center text-white bg-${color} border-0" data-toggle="toast" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="d-flex">
          <div class="toast-body">
            ${message}
          </div>
          <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
      </div>
    `;
    let temp = document.createElement("div");
    temp.innerHTML = html;
    let toast = temp.firstElementChild;
    document.querySelector(".toast-container").append(toast);
    let bstoast = new bootstrap.Toast(toast);
    bstoast.show();
  }

  reportErrorFromEvent(event) {
    var message = [
      "Error saving shift data.",
      "Status: '" + event.detail[0].status + "'",
      "Response: '" + event.detail[0].response + "'"
    ].join(" ");
    window.Sentry && Sentry.captureException(new Error(message), event);
  }
}
