// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import Sortable from "stimulus-sortable"

const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))


application.handleError = (error, message, detail) => {
  window.Sentry && Sentry.captureException(error, detail);
}

application.register('sortable', Sortable)
