import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];

  connect() {
    this.customizeSelects();
    this.addClearButtons();
    $(this.element).on("change", (_) => { this.reload() });
  }

  customizeSelects() {
    return $(this.element).find("select.belongs-to").each(function() {
      const select = $(this);
      const config = select.data();
      config.width = "100%";

      if (!config.ajax) { config.ajax = {}; }
      if (config.ajax.url.indexOf(":")>=0) {
        const object = URI(location.href).search(true);
        for (let filter in object) {
          const value = object[filter];
          if ((filter.indexOf("filters")===0) && (value!=="")) {
            var name = filter.replace("filters[","").replace("]","");
            config.ajax.url = config.ajax.url.replace(`:${name}`, value);
          }
        }
      }
      config.disabled = config.ajax.url.indexOf(":")>=0;
      config.ajax.processResults = function(data, page){
        if (Array.isArray(data)) { return {results: data}; }
        return {results: data[Object.keys(data)[0]]};
      };
      config.templateResult = obj => obj.name;
      select.select2(config);
    });
  }

  addClearButtons() {
    $(this.element).find("input.filter").each((index, elm) => {
      const cell = $(elm).parent();
      const btn = $(
        `<button type='button' class='btn btn-close btn-sm' data-action='${this.identifier}#clear' aria-label='Close'></button>`
      );
      return cell.append(btn);
    });
  }

  clear(e) {
    const input = $(e.target).parents(".filter").find("input,select");
    input.val("");
    this.reload();
  }

  reload() {
    const url = URI(location.href);
    $(this.element).find("input.filter,select.filter").each(function () {
      const name = $(this).attr("name");
      const value = $(this).val();
      url.removeSearch(name);
      if (value != "")
        url.addSearch(name, value);
    });
    location.href = url.toString();
  }
}
